import { Col, Row } from 'antd';
import { H1, H2 } from '../components/shared/typography';
import {
  IServicePage,
  ISimpleSection,
} from '../components/shared/contentfu.interface';
import { SiteLayout, StyledContent } from '../components/layout/layout';

import FadeInContainer from '../components/shared/sectionAnimation';
import Img from 'gatsby-image';
import { PageContext } from '../pageContext';
import React from 'react';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';

const StyledSection = styled.div`
  color: ${theme.colors.txtLight.black};
  align-self: center;
  padding: 2rem;
  p {
    font-size: 16px;
  }
`;

const StyledRow = styled(Row)`
  padding: 4rem;
  @media (max-width: 900px) {
    padding: 1rem;
  }
`;

const StyledSectionImg = styled(Img)`
  object-position: center;
  border-radius: 5px;
  width: 100%;
  max-height: 400px;
`;

const TriangleDownLeft = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 2rem;

  ::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-bottom: 100px solid transparent;
    border-left: 1400px solid #e7ecef;
  }
`;

const TriangleLeft = styled.div`
  width: 100%;
  overflow: hidden;

  ::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-left: 1400px solid #e7ecef;
  }
`;

interface Props {
  data: {
    allContentfulServicePage: {
      nodes: IServicePage[];
    };
  };
  pageContext: PageContext;
}

const ServicePage: React.FC<Props> = ({ pageContext, data }: Props) => {
  const content = data.allContentfulServicePage.nodes[0];
  const contentfulSections: ISimpleSection[] =
    data.allContentfulServicePage.nodes[0].sections;

  return (
    <SiteLayout
      locale={pageContext.locale}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Vores services"
        description="Hos HighView tilbyder vi alle de services i har brug for, hvis i øsnker at sikre jeres virksomhed. Vi tilbyder både systemdesign, montering, installation, vedligholdes, drift og opgraderinger af eksisterende systemer. Bi bruger udelukkende de bedste komponenter på markedet"
        image={'/HighView-Security-text-tlf.jpg'}
        lang={pageContext.locale}
        keywords={[
          'AIA',
          'ADK',
          'Privat',
          'Sikkerhed',
          'IT Sikkerhed',
          'NOX alarmanlæg',
          'Jablotron JA100',
          'HighView',
          'Overvågning',
          'Erhvers sikkerhed',
          'adgangskontrolanlæg',
        ]}
        pathname="/services"
      />
      <StyledContent>
        <Row justify={'center'} style={{ marginTop: '4rem' }}>
          <H1 style={{ textAlign: 'center' }}>{content.title}</H1>
        </Row>
        <StyledRow gutter={[0, 20]}>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
            <H2 style={{ textAlign: 'center' }}>
              {contentfulSections[0].title}
            </H2>
            <StyledSection
              dangerouslySetInnerHTML={{
                __html:
                  contentfulSections[0].section.childContentfulRichText.html,
              }}
            />
          </Col>
          <Col
            xs={{ span: 24, order: 1, offset: 0 }}
            md={{ span: 10, order: 2, offset: 2 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <StyledSectionImg
              fluid={contentfulSections[0].img.fluid}
              alt={contentfulSections[0].img.title}
            />
          </Col>
        </StyledRow>
        <FadeInContainer transition={'XL'}>
          <TriangleLeft />
          <StyledRow
            gutter={[0, 20]}
            style={{
              background: '#e7ecef',
            }}
          >
            <Col
              xs={{ span: 24, order: 2, offset: 0 }}
              md={{ span: 12, order: 2, offset: 2 }}
            >
              <H2 style={{ textAlign: 'center' }}>
                {contentfulSections[1].title}
              </H2>
              <StyledSection
                dangerouslySetInnerHTML={{
                  __html:
                    contentfulSections[1].section.childContentfulRichText.html,
                }}
              />
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 10, order: 1 }}>
              <StyledSectionImg
                fluid={contentfulSections[1].img.fluid}
                alt={contentfulSections[1].img.title}
              />
            </Col>
          </StyledRow>
          <TriangleDownLeft />
        </FadeInContainer>
        <FadeInContainer transition={'XR'}>
          <StyledRow gutter={[0, 20]}>
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <H2 style={{ textAlign: 'center' }}>
                {contentfulSections[2].title}
              </H2>
              <StyledSection
                dangerouslySetInnerHTML={{
                  __html:
                    contentfulSections[2].section.childContentfulRichText.html,
                }}
              />
            </Col>
            <Col
              xs={{ span: 24, order: 1, offset: 0 }}
              md={{ span: 10, order: 2, offset: 2 }}
            >
              <StyledSectionImg
                fluid={contentfulSections[2].img.fluid}
                alt={contentfulSections[2].img.title}
              />
            </Col>
          </StyledRow>
        </FadeInContainer>
        <FadeInContainer transition={'XL'}>
          <TriangleLeft />
          <StyledRow gutter={[0, 20]} style={{ background: '#e7ecef' }}>
            <Col
              xs={{ span: 24, order: 2, offset: 0 }}
              md={{ span: 12, order: 2, offset: 2 }}
            >
              <H2 style={{ textAlign: 'center' }}>
                {contentfulSections[3].title}
              </H2>
              <StyledSection
                dangerouslySetInnerHTML={{
                  __html:
                    contentfulSections[3].section.childContentfulRichText.html,
                }}
              />
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 10, order: 1 }}>
              <StyledSectionImg
                fluid={contentfulSections[3].img.fluid}
                alt={contentfulSections[3].img.title}
              />
            </Col>
          </StyledRow>
        </FadeInContainer>
      </StyledContent>
    </SiteLayout>
  );
};
export default ServicePage;

export const query = graphql`
  query ServicePageQuery {
    allContentfulServicePage {
      nodes {
        title
        sections {
          title
          section {
            childContentfulRichText {
              html
            }
          }
          img {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
